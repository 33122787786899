import { useEffect, useState, useRef } from "react";
import axios from "axios";
import _ from 'lodash';

import styles from './utilstyles.module.css';
import account_image from '../../../images/settings.jpg';
import notifications_image from '../../../images/notifications.jpg';
import security_image from '../../../images/security.jpg';
import password_image from '../../../images/password.jpg';
import twofa_image from '../../../images/2fa.jpg';
import email_sent from '../../../images/emailSent.jpg';


const PopUpSettings = ({ param, handleActions, userData }) => {
  const [innerLoading, setInnerLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [page, setPage] = useState(1)
  const [initialNav, setInitialNav] = useState(null)
  const [securityNav, setSecurityNav] = useState(null)
  const [notificationData, setNotificationData] = useState({})
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: ""
  })


  function handleClose(bypass=false) {
    if (bypass) {
      setInnerLoading(false)
      setInitialNav(null)
      setSecurityNav(null)
      setPage(1)
      setNotificationData({})
      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        mobile: ""
      })
      setValidationError(null)
      handleActions('close')
    } else {
      const confirmClose = window.confirm("Are you sure you would like to close this?")

      if (confirmClose) {
        setInnerLoading(false)
        setInitialNav(null)
        setSecurityNav(null)
        setPage(1)
        setNotificationData({})
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          mobile: ""
        })
        setValidationError(null)
        handleActions('close')
      }
    }
  }

  const handlePopup = async (control, name?, input?) => {
    let formDataDup = {...formData}
    let notificationDataDup = {...notificationData}

    switch (control) {
      case 'nav':
        if (name === "account") {
          setFormData({
            first_name: userData.first_name || "",
            last_name: userData.last_name || "",
            email: userData.email || "",
            phone: userData.phone || "",
          })
        } else if (name === "security") {
          setSecurityNav(name)
        } else {
          setNotificationData(userData.notifications)
        }

        setInitialNav(name)
        break;
      case 'next':
        if (page === 1 && !initialNav) {
          setValidationError("Please select one of the options to continue.")
          return false;
        }

        setValidationError(null)
        setPage(page + 1)
        break;
      case 'prev':
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          mobile: ""
        })
        setNotificationData({})
        setInitialNav(null)
        setSecurityNav(null)
        setValidationError(null)
        setPage(page - 1)
        break;
      case 'set':
        if (initialNav === "account") {
          formDataDup[name] = input.target.value
          setFormData(formDataDup)
        } else {
          notificationDataDup[name] = input.target.checked
          setNotificationData(notificationDataDup)
        }
        break;
      case 'submit':
        setInnerLoading(true)

        if (initialNav === "account") {
          let valTestSub = await validationTest()
          if (valTestSub) {
            let sendObj = {
              first_name: formData.first_name,
              last_name: formData.last_name,
              email: formData.email,
              phone: formData.phone,
              _id: userData._id
            }

            await axios.post(`/api/dashboard/${param._id}/editDetails`, sendObj);
            window.location.reload()
          }
        } else if (initialNav === "security") {
          setPage(page + 1)

          if (securityNav === "password") {
            await axios.post(`/api/users/forgot-password`, {email: userData.email});
          } else if (securityNav === "2fa") {
            await axios.get(`/api/dashboard/${param._id}/handle_2fa`);
          }
        } else {
          if (_.isEqual(userData.notifications, notificationData)) {
            setInnerLoading(false)
            setValidationError("You did not change any details")
            return false;
          } else {
            setValidationError(null)
            let sendObj = {
              notifications: notificationData,
              _id: userData._id
            }

            await axios.post(`/api/dashboard/${param._id}/editNotifications`, sendObj);
            window.location.reload()
          }
        }

        setInnerLoading(false)
    }
  }


  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const validationTest = async () => {
      let startingForm = {
        first_name: userData.first_name || "",
        last_name: userData.last_name || "",
        email: userData.email || "",
        phone: userData.phone || "",
      }

    if (_.isEqual(startingForm, formData)) {
      setValidationError("You did not change any details")
      return false;
    } else {
      if (!(formData.hasOwnProperty('first_name')) || formData['first_name'] === '') {
        setValidationError("You did enter your first name.");
        return false;
      }

      if (!(formData.hasOwnProperty('last_name')) || formData['last_name'] === '') {
        setValidationError("You did enter your last name.");
        return false;
      }

      if (!(formData.hasOwnProperty('email')) || formData['email'] === '') {
        setValidationError("You did enter your email.");
        return false;
      } else {
        if (!(isValidEmail(formData.email))) {
          setValidationError(`You did not enter a valid email.`)
          return false;
        }
      }

      if (!(formData.hasOwnProperty('phone')) || formData['phone'] === '') {
        setValidationError("You did enter your phone number.");
        return false;
      }
    }

    setValidationError(null)
    return true
  }


  return (
    <div className={`${styles.popup_container} ${styles.popup_security}`}>
      <div className={`${styles.popup_heading}`}>
        <div>
          <p className={styles.mediumer_text}>{(initialNav && initialNav === "security") ? "Security settings" : (initialNav && initialNav === "notifications") ? "Notification settings" : "Account settings"}</p>
          <p className={styles.small_text}>Use this module to change your {(initialNav && initialNav === "security") ? "security settings" : (initialNav && initialNav === "notifications") ? "notification settings" : "account settings"}</p>
        </div>

        <button onClick={() => handleClose()}><i className={`fa-solid fa-x ${styles.medium_text}`}></i></button>
      </div>

      <div className={styles.popup_content}>
        {innerLoading
          ? 
            <div className={styles.load}>
              <hr />
              <hr />
              <hr />
              <hr />
            </div>
          :
            <>
              {page === 1 &&
                <div className={styles.security_btn_container}>
                  <button className={`${styles.security_btn} ${initialNav === "account" && styles.active_security_btn}`} onClick={() => handlePopup("nav", "account")}>
                    <div className={`${styles.security_img_container} ${initialNav === "account" && styles.active_security_border}`}>
                      <img src={account_image}/>
                    </div>

                    <div className={styles.security_content_container}>
                      <p className={styles.mid_text}>Personal details</p>
                      <p className={styles.small_text}>Manage your personal details within your account here.</p>
                    </div>
                  </button>

                  <button className={`${styles.security_btn} ${initialNav === "notifications" && styles.active_security_btn}`} onClick={() => handlePopup("nav", "notifications")}>
                    <div className={`${styles.security_img_container} ${initialNav === "notifications" && styles.active_security_border}`}>
                      <img src={notifications_image}/>
                    </div>

                    <div className={styles.security_content_container}>
                      <p className={styles.mid_text}>Notification settings</p>
                      <p className={styles.small_text}>Manage your notification settings within your account here.</p>
                    </div>
                  </button>

                  <button className={`${styles.security_btn} ${initialNav === "security" && styles.active_security_btn}`} onClick={() => handlePopup("nav", "security")}>
                    <div className={`${styles.security_img_container} ${initialNav === "security" && styles.active_security_border}`}>
                      <img src={security_image}/>
                    </div>

                    <div className={styles.security_content_container}>
                      <p className={styles.mid_text}>Security settings</p>
                      <p className={styles.small_text}>Secure your account through our security options available.</p>
                    </div>
                  </button>
                </div>
              }


              {page === 2 &&
                <>
                  {initialNav === "account" 
                    ? 
                      <div className={`${styles.text_grid} ${styles.mb3}`}>
                        <div className={styles.text_area}>
                          <p className={`${styles.text_label} ${styles.small_text}`}>First name</p>
                          <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.first_name} onChange={(input) => handlePopup("set", "first_name", input)}/>
                        </div>

                        <div className={styles.text_area}>
                          <p className={`${styles.text_label} ${styles.small_text}`}>Last name</p>
                          <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.last_name} onChange={(input) => handlePopup("set", "last_name", input)}/>
                        </div>

                        <div className={styles.text_area}>
                          <p className={`${styles.text_label} ${styles.small_text}`}>Email</p>
                          <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.email} onChange={(input) => handlePopup("set", "email", input)}/>
                        </div>

                        <div className={styles.text_area}>
                          <p className={`${styles.text_label} ${styles.small_text}`}>Phone</p>
                          <input type="text" className={`${styles.general_input} ${styles.mid_text}`} value={formData.phone} onChange={(input) => handlePopup("set", "phone", input)}/>
                        </div>
                      </div>

                    : initialNav === "notifications"
                    ?
                      <div className={`${styles.text_grid} ${styles.mb3}`}>
                        <div className={styles.toggle_area}>
                          <div className={styles.toggle_area_content}>
                            <p className={`${styles.text_label} ${styles.smaller_text}`}>Job Scheduled</p>
                            <p className={`${styles.small_text}`}>Receive emails when inspections and services get scheduled.</p>
                          </div>

                          <label className={styles.switch}>
                            <input type="checkbox" checked={notificationData.job_scheduled} onChange={(toggle) => handlePopup("set", "job_scheduled", toggle)}></input>
                            <span className={`${styles.slider} ${styles.round}`}></span>
                          </label>
                        </div>

                        <div className={styles.toggle_area}>
                          <div className={styles.toggle_area_content}>
                            <p className={`${styles.text_label} ${styles.smaller_text}`}>New Reports Available</p>
                            <p className={`${styles.small_text}`}>Receive emails when compliance, service and case reports are ready.</p>
                          </div>

                          <label className={styles.switch}>
                            <input type="checkbox" checked={notificationData.new_reports_available} onChange={(toggle) => handlePopup("set", "new_reports_available", toggle)}></input>
                            <span className={`${styles.slider} ${styles.round}`}></span>
                          </label>
                        </div>

                        <div className={styles.toggle_area}>
                          <div className={styles.toggle_area_content}>
                            <p className={`${styles.text_label} ${styles.smaller_text}`}>New Quote Available</p>
                            <p className={`${styles.small_text}`}>Receive emails when quotes are available regarding follow-up work.</p>
                          </div>

                          <label className={styles.switch}>
                            <input type="checkbox" checked={notificationData.new_quote_available} onChange={(toggle) => handlePopup("set", "new_quote_available", toggle)}></input>
                            <span className={`${styles.slider} ${styles.round}`}></span>
                          </label>
                        </div>

                        <div className={styles.toggle_area}>
                          <div className={styles.toggle_area_content}>
                            <p className={`${styles.text_label} ${styles.smaller_text}`}>Property Transfer</p>
                            <p className={`${styles.small_text}`}>Receive emails when a property has been transferred to another agent.</p>
                          </div>

                          <label className={styles.switch}>
                            <input type="checkbox" checked={notificationData.property_transfer} onChange={(toggle) => handlePopup("set", "property_transfer", toggle)}></input>
                            <span className={`${styles.slider} ${styles.round}`}></span>
                          </label>
                        </div>

{/*                         <div className={styles.toggle_area}> */}
{/*                           <div className={styles.toggle_area_content}> */}
{/*                             <p className={`${styles.text_label} ${styles.smaller_text}`}>Unrecognised Login</p> */}
{/*                             <p className={`${styles.small_text}`}>Receive emails when your account receives an unusual login.</p> */}
{/*                           </div> */}
{/*  */}
{/*                           <label className={styles.switch}> */}
{/*                             <input type="checkbox" checked={notificationData.unrecognised_login} onChange={(toggle) => handlePopup("set", "unrecognised_login", toggle)}></input> */}
{/*                             <span className={`${styles.slider} ${styles.round}`}></span> */}
{/*                           </label> */}
{/*                         </div> */}
                      </div>
                    :
                      <div className={styles.security_btn_container}>
                        <button className={`${styles.security_btn} ${securityNav === "password" && styles.active_security_btn}`} onClick={() => handlePopup("nav", "password")}>
                          <div className={`${styles.security_img_container} ${securityNav === "password" && styles.active_security_border}`}>
                            <img src={password_image}/>
                          </div>

                          <div className={styles.security_content_container}>
                            <p className={styles.mid_text}>Change password</p>
                            <p className={styles.small_text}>You will be sent an email with instructions to reset your password.</p>
                          </div>
                        </button>

                        <button className={`${styles.security_btn} ${securityNav === "2fa" && styles.active_security_btn}`} onClick={() => handlePopup("nav", "2fa")}>
                          <div className={`${styles.security_img_container} ${securityNav === "2fa" && styles.active_security_border}`}>
                            <img src={twofa_image}/>
                          </div>

                          <div className={styles.security_content_container}>
                            <p className={styles.mid_text}>2-factor authentication</p>
                            <p className={styles.small_text}>Secure your account with 2fa through an email code on login.</p>
                          </div>
                        </button>
                      </div>
                  }
                </>
              }

              {(page === 3 && initialNav === "security") &&
                <div className={styles.confirmation_container}>
                  <img src={email_sent}></img>
                  {securityNav === "password" && <p className={styles.medium_text}>Please check your inbox as we have sent you an email with prompts to follow in order to reset your password!</p>}
                  {securityNav === "2fa" && <p className={styles.medium_text}>2 factor authentication has now been turned {userData.two_factor_auth ? "off" : "on"} for this account. {!userData.two_factor_auth && "Each login will now require a code sent to your email!"}</p>}
                </div>
              }
            </>
        }
      </div>

      {validationError &&
        <div className={styles.floating_error_container}>
          <div className={styles.floating_error}>
            <p className={styles.smaller_text}>{validationError}</p>
          </div>
        </div>
      }

      <div className={styles.controls_container}>
        {(page === 1 || page !== 2 && page !== 3) && <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('next')}><i className={`fa-solid fa-chevron-right ${styles.medium_text}`}></i></button>}
        {(page !== 1 && page !== 3) && <button className={`${styles.control_btn} ${styles.prev_btn}`} disabled={innerLoading} onClick={() => handlePopup('prev')}><i className={`fa-solid fa-chevron-left ${styles.medium_text}`}></i></button>}
        {(page === 2) && <button className={`${styles.control_btn} ${styles.next_btn}`} disabled={innerLoading} onClick={() => handlePopup('submit')}><i className={`fa-solid fa-check ${styles.medium_text}`}></i></button>}
      </div>
    </div>
  );
};

export default PopUpSettings;